// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_5551 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;

  &:before {
    position: absolute;
    content: "";
    background: $gold-color;
    height: 10px;
    width: 465px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.backstretch {
  position: relative;

  // top gradient
  &:before {
    @include before(100%, 450px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 90%);
    top: 125px;
    left: 0;

    @media only screen and (max-width: 990px) {
      top: 86px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 90%);
    }
  }

  // bottom gradient
  &:after {
    @include before(100%, 450px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 90%);
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  right: 160px;
  bottom: 70px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 48px;
  font-weight: 400;

  @media only screen and (max-width: 1250px) {
    left: 0;
    right: 0;
  }

  @media only screen and (max-width: 990px) {
    font-size: clamp(2.19rem, calc(1.59rem + 2.99vw), 5.5rem);
  }

}
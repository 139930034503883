// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #221d66;
$h2-color: #a88864;
$h3-bg-color: #221d66;
$h4-bg-color: #a88864;

$blue-color: #221d66;
$gold-color: #C59966;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Open Sans', sans-serif;
}

.body_5551 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_5551 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_5551 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
	position: relative;
	background: rgba($blue-color, 0.9);
	height: 125px;

	@media only screen and (max-width: 990px) {
		height: auto;
		background: none;
	}

}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
	width: 90%;

	@media only screen and (max-width: 1240px) {
		width: 95%;
	}

}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	width: fit-content;
	position: relative;
	left: 110px;
	top: 50%;
	transform: translateY(-50%);

	@media only screen and (max-width: 1240px) {
		left: 0;
	}

	@media only screen and (max-width: 990px) {
		transform: none;
		top: 140px;
	}

	a {
		display: flex;
		align-items: center;
		gap: 30px;

		@media only screen and (max-width: 990px) {
			flex-direction: column;
			gap: 25px;
		}

		img#logo {}

		h2 {
			color: $gold-color;
			font-size: 24px;
			margin: -10px 0 0 0;

			@media only screen and (max-width: 990px) {
				font-size: 32px;
				font-weight: 600;
				text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.6);
			}
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 45px;
	top: 25px;
	z-index: 99;

	@media only screen and (max-width: 1240px) {
		right: 0;
	}

	@media only screen and (max-width: 990px) {
		top: 100px;
	}

	li {
		display: inline-block;
		@include box(25px);
		padding: 0;
		background: none;
		margin: 0 10px;

		@media only screen and (max-width: 990px) {
			margin: 0 3px;
		}

		a {
			display: block;
			@include box(100%);
		}

		//	#facebook-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/fb-ic.png) no-repeat center; } 
		//	#twitter-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/tw-ic.png) no-repeat center; } 
		//	#instagram-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(25px);
	overflow: hidden;
	background: url(/i/design/translate-ic.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(25px);
	background: url(/i/design/search-ic.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: $blue-color;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 60px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

// home page alternate
.body_5551 #mainholder {
	padding: 0;
	margin: 0;
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 50px 0 100px 0;
	background: #1A1653;
	position: relative;
	overflow: hidden;

	@media only screen and (max-width: 990px) {
		padding: 50px 0 0 0;
	}

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/windmill-watermark.png);
		height: 926px;
		width: 926px;
		z-index: 0;
		top: 40px;
		left: 190px;
		pointer-events: none;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}


	.header-wrapper {
		position: relative;
		width: 80%;
		margin: 0 auto;

		@media only screen and (max-width: 1100px) {
			width: 990px;
		}

		@media only screen and (max-width: 1060px) {
			padding: 0 0 0 30px;
		}

		@media only screen and (max-width: 990px) {
			width: 85%;
			padding: 0;
		}

		h2 {
			color: #fff;
			font-size: clamp(1.88rem, calc(0.72rem + 6.17vw), 10.0rem);
			font-weight: 300;
			text-align: left;
			margin: 0 0 50px 0;
			line-height: 1.2;

			@media only screen and (max-width: 1270px) {
				margin: 20px 0 20px 0;
			}

			@media only screen and (max-width: 990px) {
				text-align: center;
				line-height: normal;
			}

			span {
				display: block;
				font-size: 30px;
				line-height: 24px;
				font-weight: 400;

				@media only screen and (max-width: 990px) {
					line-height: normal;
				}

				@media only screen and (max-width: 500px) {
					font-size: 16px;
				}
			}
		}

	}

	.wrapper {
		padding: 0 150px 0 0;
		position: relative;
		z-index: 100;

		@media only screen and (max-width: 1520px) {
			padding: 0 200px 0 0;
		}

		@media only screen and (max-width: 1420px) {
			padding: 0 250px 0 0;
		}

		@media only screen and (max-width: 1270px) {
			padding: 20px 250px 0 0;
		}

		@media only screen and (max-width: 1060px) {
			padding: 20px 300px 0 30px;
		}

		@media only screen and (max-width: 990px) {
			padding: 20px 0;
			width: 85%;
		}

	}

	p {
		color: #fff;
		font-weight: 300;
		font-size: 18px;
		line-height: 24px;

		@media only screen and (max-width: 1135px) {
			font-size: 17px;
			line-height: 1.2;
		}

		@media only screen and (max-width: 990px) {
			text-align: center;
		}
	}

	.read-more {
		position: relative;
		display: block;
		height: 60px;
		width: 257px;
		background: $blue-color;
		border-radius: 30px;
		float: right;
		margin: 40px 90px 0 0;
		transition: 0.4s;

		&:hover {
			background: #fff;

		}

		@media only screen and (max-width: 990px) {
			float: none;
			margin: 0 auto;
			margin-top: 50px;
		}

		span {
			line-height: 60px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 300;
			display: block;
			text-align: center;
			transition: 0.3s;

			&:hover {
				color: $blue-color;
			}
		}
	}

	.head-pic {
		position: absolute;
		top: 110px;
		right: 100px;

		@media only screen and (max-width: 1730px) {
			scale: 0.9;
			top: 135px;
			right: -40px;
		}

		@media only screen and (max-width: 1420px) {
			scale: 0.85;
			top: 155px;
		}

		@media only screen and (max-width: 1270px) {
			scale: 0.7;
			top: 185px;
			right: -80px;
		}

		@media only screen and (max-width: 1135px) {
			top: 95px;
			right: -130px;
		}

		@media only screen and (max-width: 990px) {
			position: relative;
			top: auto;
			right: auto;
			scale: 1;
			bottom: -70px;
		}

		// &:before {
		// 	position: absolute;
		// 	content: "Mr P Berry, Headteacher";
		// 	color: #fff;
		// 	font-size: 18px;
		// 	font-weight: 300;
		// 	bottom: 210px;
		// 	right: 100px;
		// }

		img {

			@media only screen and (max-width: 990px) {
				margin: 0 auto;
				max-width: 450px;
			}
		}
	}
}

// -----------------------------------//
// SIXTH FORM SEC
// -----------------------------------//

#sixth-form {
	background: $gold-color;
	position: relative;

	&::before {
		position: absolute;
		content: "";
		background: url(/i/design/white-triangle.png) no-repeat;
		background-size: contain;
		height: 551px;
		width: 2200px;
		top: -186px;
		left: -238px;
		pointer-events: none;
		transform: rotate(-4deg);

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.header-wrapper {
		position: relative;
		width: 80%;
		margin: 0 auto;

		@media only screen and (max-width: 1300px) {
			width: 90%;
		}

		h2 {
			color: $gold-color;
			font-size: clamp(1.88rem, calc(0.72rem + 6.17vw), 10.0rem);
			font-weight: 300;
			text-align: left;
			margin: 0 0 50px 0;
			line-height: 1.2;
			z-index: 100;

			@media only screen and (max-width: 990px) {
				color: #fff;
				padding: 50px 0 0 0;
				text-align: center;
				margin: 0;
				line-height: normal;
			}

			span {
				display: block;
				font-size: 30px;
				line-height: 24px;
				font-weight: 400;

				@media only screen and (max-width: 990px) {
					line-height: normal;
				}

				@media only screen and (max-width: 500px) {
					font-size: 16px;
				}

			}
		}

	}

	.wrapper {
		width: 100%;
		position: relative;
		display: flex;
		z-index: 100;

		@media only screen and (max-width: 990px) {
			flex-direction: column-reverse;
			align-items: center;
		}

		&:before {}

		.left {
			position: relative;
			width: 50%;
			display: flex;
			background: $gold-color;
			align-items: center;

			@media only screen and (max-width: 990px) {
				flex-direction: column-reverse;
				width: 95%;
				padding: 0 0 50px 0;
				gap: 15px;
			}


			.img-1,
			.img-2 {
				position: relative;
				width: 50%;
				background-size: cover;

				img {
					flex-shrink: 0;
				}
			}

			.img-1 {}

			.img-2 {}
		}

		.right {
			position: relative;
			width: 50%;
			background: $gold-color;
			padding: 40px 180px 40px 50px;

			@media only screen and (max-width: 1570px) {
				padding: 40px 50px 40px 50px;
			}

			@media only screen and (max-width: 990px) {
				width: 100%;
				text-align: center;
				padding: 40px 25px;
			}

			&:before {
				position: absolute;
				content: "";
				background: url(/i/design/gold-bg.png) no-repeat;
				height: 220px;
				width: 105%;
				left: 0;
				top: -158px;
				z-index: -1;

				@media only screen and (max-width: 1370px) {
					background: url(/i/design/gold-bg-mob.png) no-repeat;
					height: 495px;
					width: 1875px;
					top: -255px;
					transform: rotate(-9deg);
					left: -514px;
				}

				@media only screen and (max-width: 1170px) {
					transform: rotate(-17deg);
					left: -494px;
				}

				@media only screen and (max-width: 990px) {
					display: none;
				}
			}

			p {
				color: #fff;
				font-size: 18px;
				line-height: 24px;
				font-weight: 300;

			}

			.btns {
				position: relative;
				margin: 40px 0 0 0;

				@media only screen and (max-width: 1300px) {
					display: flex;
				}

				@media only screen and (max-width: 1230px) {
					flex-direction: column;
					align-items: center;
					gap: 15px;
				}

				@media only screen and (max-width: 990px) {}


				a {
					position: relative;
					display: inline-block;
					height: 60px;
					width: 257px;
					border-radius: 30px;
					transition: 0.4s;
					flex-shrink: 0;

					&:hover {
						background: $blue-color !important;
						color: #fff !important;
						border: none !important;

					}

					@media only screen and (max-width: 990px) {}

					&:nth-of-type(1) {
						background: #fff;
						color: $gold-color;
						margin: 0 15px 0 0;

						@media only screen and (max-width: 1230px) {
							margin: 0;
						}
					}

					&:nth-of-type(2) {
						background: $gold-color;
						border: 1px solid;
						color: #fff;
					}

					span {
						line-height: 60px;
						text-transform: uppercase;
						font-weight: 300;
						display: block;
						text-align: center;
					}
				}
			}
		}
	}
}

// -----------------------------------//
// NEWS DIARY SEC
// -----------------------------------//

#news-diary-sec {
	position: relative;
	background: #1F1B68;
	padding: 50px 0 150px 0;
	overflow: hidden;

	&:before {
		position: absolute;
		content: "";
		background: #fff;
		height: 10px;
		width: 465px;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	.wrapper {
		width: 80%;
		position: relative;

		@media only screen and (max-width: 1390px) {
			width: 95%;
		}

		.header-wrapper {
			position: relative;
			// width: 80%;
			margin: 0 auto;
			padding: 20px 0 0 0;

			@media only screen and (max-width: 1300px) {
				width: 90%;
			}

			h2 {
				color: #fff;
				font-size: clamp(1.88rem, calc(0.72rem + 6.17vw), 10.0rem);
				font-weight: 300;
				text-align: left;
				margin: 0;

				@media only screen and (max-width: 1190px) {
					text-align: center;
				}
			}
		}



		#SideHeadingDiary {
			position: relative;

			.btn-wrap {
				position: absolute;
				top: 60px;
				right: 0;
				z-index: 10;

				@media only screen and (max-width: 1700px) {
					top: 180px;
					left: 0;
					right: 0;
				}

				@media only screen and (max-width: 1360px) {}

				@media only screen and (max-width: 1190px) {
					position: relative;
					top: auto;
					right: auto;
					margin: 40px 0 0 0;
				}



				a {
					position: relative;
					display: inline-block;
					height: 60px;
					width: 257px;
					border-radius: 30px;
					transition: 0.4s;

					&:hover {
						background: $gold-color !important;
						color: #fff !important;
						border: none !important;

					}

					@media only screen and (max-width: 690px) {
						display: block;
						margin: 0 auto;
					}

					&:nth-of-type(1) {
						background: #fff;
						color: $blue-color;
						margin: 0 15px 0 0;

						@media only screen and (max-width: 690px) {
							margin: 0 auto;
							margin-bottom: 15px;
						}
					}

					&:nth-of-type(2) {
						background: $blue-color;
						border: 1px solid;
						color: #fff;
					}

					span {
						line-height: 60px;
						text-transform: uppercase;
						font-weight: 300;
						display: block;
						text-align: center;
					}
				}
			}

			ul {
				display: flex;
				position: relative;
				padding: 140px 0 0 0;
				justify-content: space-between;

				@media only screen and (max-width: 1190px) {
					flex-wrap: wrap;
					justify-content: center;
					gap: 25px;
					padding: 70px 0 0 0;
				}

				&:before {
					position: absolute;
					content: "";
					background: $gold-color;
					height: 1px;
					width: 80%;
					top: 240px;
					left: 50%;
					transform: translateX(-50%);

					@media only screen and (max-width: 1190px) {
						display: none;
					}
				}

				li {
					position: relative;
					text-align: center;
					transition: 0.4s;

					&:hover {
						filter: brightness(105%);

						p.date {
							translate: 0 -2px;
						}

						p.title {
							text-decoration: rgba(255, 255, 255, 0.4) underline dotted;
						}
					}

					a {
						position: relative;
						flex-shrink: 0;

						img {
							margin: 0 auto;
							filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.4));
						}


						p.date {
							position: absolute;
							top: 0;
							left: 50%;
							transform: translateX(-50%);
							margin: 50px 0 0 0;
							color: #fff;
							font-size: 48px;
							line-height: 48px;
							font-weight: 800;
							text-transform: uppercase;

							// Span = number
							span {
								display: block;
								transition: .5s;
							}
						}

						p.title {
							max-width: 246px;
							color: #fff;
							display: block;
							margin: 45px 0 0 0;

							span {
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								text-overflow: ellipsis;
								font-size: 18px;
								line-height: 24px;

							}

						}
					}
				}
			}
		}
	}
}


// -----------------------------------//
// HOUSE POINTS
// -----------------------------------//

#house-points-sec {
	padding: 50px 0 100px 0;
	background: url(/i/design/house-points-bg.jpg);
	max-height: 736px;

	@media only screen and (max-width: 1100px) {
		background: #202020;
		max-height: none;
	}

	.wrapper {
		width: 80%;

		h2 {
			color: #fff;
			font-size: clamp(1.88rem, calc(0.72rem + 6.17vw), 10.0rem);
			font-weight: 300;
			margin: 0 0 70px 0;

			@media only screen and (max-width: 1190px) {
				text-align: center;
			}
		}

		.rolling-stats {
			max-width: 1150px;
			margin: 0 auto;

			ul {
				display: flex;
				justify-content: space-between;
				gap: 30px;

				@media only screen and (max-width: 1100px) {
					flex-wrap: wrap;
					justify-content: center;
					gap: 50px;
				}

				li {
					background: none;
					padding: 0;
					margin: 0;
					flex-shrink: 0;
					text-align: center;
					font-size: 30px;
					font-weight: 800;
					text-transform: uppercase;


					img {
						margin: 0 0 15px 0;
					}

					span {
						color: #fff;
						font-size: 60px;
						line-height: 60px;
					}

					&:nth-of-type(1) {

						color: #201C69;

						img {
							// https://codepen.io/sosuke/pen/Pjoqqp change colours of windmills
							filter: invert(13%) sepia(33%) saturate(5787%) hue-rotate(237deg) brightness(79%) contrast(102%);
						}
					}

					&:nth-of-type(2) {
						color: #972846;

						img {
							filter: invert(23%) sepia(19%) saturate(5133%) hue-rotate(313deg) brightness(92%) contrast(95%);
						}
					}

					&:nth-of-type(3) {
						color: #F47000;

						img {
							filter: invert(59%) sepia(52%) saturate(6419%) hue-rotate(7deg) brightness(101%) contrast(103%);
						}
					}

					&:nth-of-type(4) {
						color: #CE3300;

						img {
							filter: invert(29%) sepia(95%) saturate(5720%) hue-rotate(19deg) brightness(94%) contrast(101%);
						}
					}

					&:nth-of-type(5) {
						color: #00AC47;

						img {
							filter: invert(42%) sepia(86%) saturate(1852%) hue-rotate(118deg) brightness(91%) contrast(105%);
						}
					}
				}
			}
		}
	}


}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		// height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: $blue-color;
	border-bottom: 5px solid $gold-color;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: #1F1B68;
	padding: 20px 0;
	position: relative;
	bottom: 0;
	z-index: 10;
	border-bottom: 1px solid $gold-color;

	@media only screen and (max-width: 1180px) {
		padding: 35px 0;
	}

	.wrapper {
		text-align: left;
		font-size: 14px;
		width: 85%;
		margin: 0 auto;
		text-transform: uppercase;

		@media only screen and (max-width: 1390px) {
			width: 95%;
		}

		@media only screen and (max-width: 1240px) {
			font-size: 13px;
		}

		@media only screen and (max-width: 1180px) {
			font-size: 15px;
		}
	}

	.flex {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 0 auto;
		gap: 25px;

		@media only screen and (max-width: 1180px) {
			flex-direction: column;
			gap: 15px;
			align-items: center;
		}

		@media only screen and (max-width: 990px) {
			gap: 0px;
		}

	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

}

p.address {

	@media only screen and (max-width: 990px) {}

	span {
		text-transform: uppercase;
		font-weight: 600;
		display: block;
	}
}

p.telephone {}

footer a[href^="mailto:"] {
	display: block;
	color: $gold-color;
}

.compass-education-trust {
	margin-left: auto;

	@media only screen and (max-width: 1180px) {
		margin-left: 0;
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	padding: 15px 0 30px 0;
	background: #1F1B68;
	overflow: hidden;

	@media only screen and (max-width: 1180px) {
		padding: 20px 0 !important;
	}


	.wrapper {
		width: 85%;
		display: flex;
		gap: 35px;

		@media only screen and (max-width: 1390px) {
			width: 95%;
		}

		@media only screen and (max-width: 1180px) {
			flex-direction: column;
			gap: 0;
			align-items: center;
		}
	}

	p {
		text-transform: uppercase;
		text-align: left;
		font-size: 14px;
		color: #fff;
		margin: 0;

		@media only screen and (max-width: 1240px) {
			font-size: 13px;
		}

		@media only screen and (max-width: 1180px) {
			font-size: 15px;
		}

	}

	a {
		color: #fff;
	}

	#copyright,
	#credit {
		display: inline-block;

		a {
			color: #fff;
		}
	}

	#copyright {
		a {
			color: $gold-color;
		}
	}

	#credit {
		a {

			&:nth-of-type(2) {
				color: $gold-color;
			}
		}
	}
}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;
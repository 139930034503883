// <section id="social">

// <!-- Iframe container -->
// <!-- <iframe src="/inc/social/index.asp"></iframe> -->

// <!-- Close button -->
// <div id="close-social"><img src="/i/design/close.png" width="42" height="42" alt="Close"><br />Close</div>

// <!-- Sidebar -->
// <div id="sidebar">
// 	<h2>Social Media Posts</h2>
// </div>

// </section>

// // ------------------------------ 	Social Wall

// var isSliderOpen = false;
// var socialWidth = $('section#social').width();
// possible fix for sticky-out bit
// // var socialWidth = window.innerWidth;

// $('section#social').css({
// 	'right': '-' + socialWidth + 'px',
// 	'visibility': 'visible'
// });

// $('section#social div#sidebar').click(function () {
// 	if (isSliderOpen === false) {
// 		$('section#social').animate({
// 			right: 0
// 		}, 1000);
// 		$('div#sidebar').addClass('open');
// 		$('section#social').css({
// 			'overflow': 'visible',
// 			'-webkit-overflow-scrolling': 'touch'
// 		});
// 		isSliderOpen = true;
// 	} else {
// 		$('section#social').animate({
// 			right: '-' + socialWidth + 'px'
// 		}, 1000);
// 		$('section#social div#sidebar').removeClass('open');
// 		$('section#social').css({
// 			'overflow': 'visible'
// 		});
// 		isSliderOpen = false;
// 	}
// });
// $('#close-social').click(function () {
// 	$('section#social').animate({
// 		right: '-' + socialWidth + 'px'
// 	}, 1000);
// 	$('section#social div#sidebar').removeClass('open');
// 	$('section#social').css({
// 		'overflow': 'visible'
// 	});
// 	isSliderOpen = false;
// });

section#social {
	width: 100%;
	height: 100% !important;
	position: fixed;
	top: 0;
	left: auto;
	visibility: hidden;
	z-index: 99999;
	background: rgba(0, 0, 0, .9);

	iframe {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	#sidebar {
		position: absolute;
		transition: all 0.2s ease-out;
		cursor: pointer;
		z-index: 99999;

		background: $blue-color;
		height: 250px;
		width: 70px;

		left: -70px;
		top: calc(85% - 200px);

		h2 {
			position: absolute;
			top: 90px;
			color: #fff;
			white-space: nowrap;
			transform: rotate(90deg);
			font-size: 18px;
			text-transform: uppercase;
			font-weight: 600;
			left: -25px;
		}
	}
}

#close-social {
	position: absolute;
	top: 15px;
	right: 30px;
	font-size: 13px;
	font-weight: 400;
	text-align: center;
	color: #fff;
	cursor: pointer;
	text-transform: uppercase;
}

#close-social img {
	padding: 14px 13px 13px 13px;
	border-radius: 50%;
	margin-bottom: -20px;
	filter: brightness(1000%);
}

#close-social:hover {
	opacity: .7;
}

#close-social:hover img {
	opacity: .7;
}

.backstretch {
	height: 100%;
}

@media only screen and (max-width: 1170px) {

	section#social {
		display: none;
	}
}

#timeline_wall1.sboard .sb-item .sb-container .sb-inner {
	padding: 5px 15px !important;
}

#timeline_wall1.sboard .bg-twitter {
	background: red !important;
}